import { h } from 'preact';
import styles from "./styles.scss";

const LinksToSocialNetworks = () => {
 return (
   <div className={styles.links}>
     <a href="https://vk.com/itrum" target="_blank" rel="noreferrer">
       <img src="/assets/components/footer/media_social_vk_icon.svg" alt="vk link" />
     </a>
     <a href="https://www.behance.net/itrum" target="_blank" rel="noreferrer">
       <img src="/assets/components/footer/media_social_behance_icon.svg" alt="behance link" />
     </a>
     <a href="https://dribbble.com/ITRUM" target="_blank" rel="noreferrer">
       <img src="/assets/components/footer/media_social_dribble_icon.svg" alt="linked in link" />
     </a>
     {/*<a href="https://dribbble.com/ExceedTeam" target="_blank" rel="noreferrer">*/}
     {/*  <img src="/assets/components/footer/media_social_dribble_icon.svg" alt="dribble link" />*/}
     {/*</a>*/}
   </div>
 );
};

export default LinksToSocialNetworks;
