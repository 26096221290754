import { h } from 'preact';
const Text = ({children}) => children;
import LinksToSocialNetworks from "../LinksToSocialNetworks/LinksToSocialNetworks";
import styles from "./styles.scss";

const Footer = () => {
 return (
   <footer>
     <div className={styles.footerContent}>
       <div className={styles.footerLeft}>
         <div className={styles.logo}>
           <img src="/assets/logo-itrum.svg" alt="logo" title="logo" />
         </div>
         <p className={styles.footerInfo}><Text id='footer.year'>©ИТРУМ 2022</Text></p>
         <p className={styles.footerInfo}><Text id='footer.protected'>Все права защищены</Text></p>
         <a onClick={() => location.href = "/privacy"} ><Text id='footer.privacy_policy'>Политика конфиденциальности</Text></a>
       </div>
       <div className={styles.footerRight}>
         <div className={styles.footerPhone}><a href="tel:+7-991-077-17-13">+ 7 (991) 077-17-13</a></div>
         <div className={styles.footerEmail}><a href="mailto:welcome@itrum.ru">welcome@itrum.ru</a></div>
         <LinksToSocialNetworks />
       </div>
     </div>

     <div className={styles.backgroundCircle} />
   </footer>
 );
};

export default Footer;
